import { settlePayment, authorizePayment } from "./apiService";
/*
Since Authorization call can be made from multiple flows
This method would reduce code repetition 
*/
export async function authorizationCall(response, depositAmount, tokenizationResponse, paymentAuthorizationLink,transactionReference,navigate,cardNumber=null,cardHolderName=null)
{
    const authVer = response[1];
    const authVal = response[2];
    const authEci = response[3];
    const authTransactionId = response[4];
    

    const authorizePaymentResponse = await authorizePayment(depositAmount,tokenizationResponse,authVer,authEci,authVal,authTransactionId,paymentAuthorizationLink,transactionReference)
    if (authorizePaymentResponse){
        if (authorizePaymentResponse[0] === "authorized"){
        console.log(authorizePaymentResponse[1]);
        settlePayment(transactionReference, authorizePaymentResponse[1],depositAmount,"Deposit through Worldpay",cardNumber,cardHolderName,true);
        navigate('/paymentResponse?response=success'); //If Authorization passes => Transaction approved
        } else {
        settlePayment(transactionReference, null, depositAmount, "Deposit through Worldpay - Authorization failed", cardNumber, cardHolderName, false);
        navigate('/paymentResponse?response=failed') //If Authorization fails => Transaction failed
        }
    } else {
        settlePayment(transactionReference, null, depositAmount, "Deposit through Worldpay - No Authorization Response", cardNumber, cardHolderName, false);
        navigate('/paymentResponse?response=failed') //If No Authorization received => Transaction failed
    }
}