import React,{ useState, useEffect } from "react";
import './bankDetails.css'
import { addNewBank } from "../../service/apiService";

const BankDetails = () => {
    //State Variables to handle field changes
    const [bankName, setBankName] = useState('');
    const [payee, setPayee] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [bankCode, setBankCode] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postcode, setPostcode] = useState('');

    const [isFormValid, setIsFormValid] = useState(false); //State variable to control form validity

    //State Variables for validity of each field
    const [bankNameValid, setBankNameValid] = useState(false);
    const [payeeValid, setPayeeValid] = useState(false);
    const [accountNumberValid, setAccountNumberValid] = useState(false);
    const [branchCodeValid, setBranchCodeValid] = useState(false);
    const [bankCodeValid, setBankCodeValid] = useState(false);
    const [addressLine1Valid, setAddressLine1Valid] = useState(false);
    const [addressLine2Valid, setAddressLine2Valid] = useState(false);
    const [cityValid, setCityValid] = useState(false);
    const [stateValid, setStateValid] = useState(false);
    const [postcodeValid, setPostcodeValid] = useState(false);
    


    const handleBankNameChange = (event) => {
        setBankName(event.target.value);
        setBankNameValid(bankName.length >= 4 && bankName.length <= 50); //MAKE DROPDOWN IN FUTURE
    }

    const handlePayeeChange = (event) => {
        setPayee(event.target.value);
        setPayeeValid(payee.length >= 3 && payee.length <= 30);
    }
    const handleAccountNumberChange = (event) => {
        setAccountNumber(event.target.value);
        setAccountNumberValid(accountNumber.length >= 5 && accountNumber.length <= 12);
    };
    const handleBranchCodehange = (event) => {
        console.log(event.target.value, branchCode.length)
        setBranchCode(event.target.value);
        setBranchCodeValid(branchCode.length+1 === 5);
        
    }
    const handleBankCodeChange = (event) => {
        setBankCode(event.target.value);
        setBankCodeValid(bankCode.length+1 === 3);
    }
    const handleAddressLine1Change = (event) => {
        setAddressLine1(event.target.value);
        setAddressLine1Valid(addressLine1.length >= 3 && addressLine1.length <= 50);
    }
    const handleAddressLine2Change = (event) => {
        setAddressLine2(event.target.value);
        setAddressLine2Valid(addressLine2.length <= 50);
    };
    const handleCityChange = (event) => {
        setCity(event.target.value);
        setCityValid(city.length >= 2 && city.length <= 35);
    };
    const handleStateChange = (event) => {
        setState(event.target.value);       
        setStateValid(state.length >= 2 && state.length <= 30); //MAKE DROPDOWN IN FUTURE
    };
    const handlePostcodeChange = (event) => {
        setPostcode(event.target.value);
        setPostcodeValid(postcode.length >= 3 && postcode.length <= 10); //MAKE DROPDOWN IN FUTURE
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      // Handle the form submission securely, e.g., send data to the server or a payment gateway
      // Avoid directly handling sensitive information on the client-side
      if (isFormValid){
        addNewBank(payee,accountNumber,branchCode,bankCode,bankName,addressLine1,addressLine2,city,state,postcode)
        console.log('Submitted bank details:', {
          accountNumber,
        });
      }
    };

    useEffect(() => {
      //checkLengthRestrictions();

      setIsFormValid(
        bankNameValid &&
        payeeValid &&
        accountNumberValid &&
        branchCodeValid &&
        bankCodeValid &&
        addressLine1Valid &&
        addressLine2Valid &&
        cityValid &&
        stateValid &&
        postcodeValid
      );
    }, [bankName, payee, accountNumber, branchCode, bankCode, addressLine1, addressLine2, city, state, postcode]);
    

    return (
    <div className="container">
      <div className="logo">
        <img src={"https://www.buddybet.com/au/wp-content/uploads/sites/2/2022/04/Buddybet-Logo.png"} alt="Logo" />
      </div>
      <div className="title">Add Amount</div>
      <div className="balance-section">
        <span className="balance-text">Current Balance</span>
        <span className="balance-amount">$ 50</span> 
      </div>

      <div className="error-container"/>

      <form onSubmit={handleSubmit}>
        <input
          className={bankNameValid?"valid-input":"invalid-input"}
          type="text"
          id="bankName"
          placeholder="Bank Name"
          value={bankName}
          onChange={handleBankNameChange}
          required
        />

        <input
          className={payeeValid?"valid-input":"invalid-input"}
          type="text"
          id="payee"
          placeholder="Payee"
          value={payee}
          onChange={handlePayeeChange}
          required
        />

        <input
          className={accountNumberValid?"valid-input":"invalid-input"}
          type="text"
          id="accountNumber"
          placeholder="Account Number"
          value={accountNumber}
          onChange={handleAccountNumberChange}
          required
        />

        <input
          className={branchCodeValid?"valid-input":"invalid-input"}
          type="text"
          id="branchCode"
          placeholder="Branch Code"
          value={branchCode}
          onChange={handleBranchCodehange}
          required
        />

        <input
          className={bankCodeValid?"valid-input":"invalid-input"}
          type="text"
          id="bankCode"
          placeholder="Bank Code"
          value={bankCode}
          onChange={handleBankCodeChange}
          required
        />

        <input
          className={addressLine1Valid?"valid-input":"invalid-input"}
          type="text"
          id="addressLine1"
          placeholder="Address Line 1"
          value={addressLine1}
          onChange={handleAddressLine1Change}
          required
        />

        <input
          className={addressLine2Valid?"valid-input":"invalid-input"}
          type="text"
          id="addressLine2"
          placeholder="Address Line 2"
          value={addressLine2}
          onChange={handleAddressLine2Change}
        />

        <input
          className={cityValid?"valid-input":"invalid-input"}
          type="text"
          id="city"
          placeholder="City"
          value={city}
          onChange={handleCityChange}
          required
        />

        <input
          className={stateValid?"valid-input":"invalid-input"}
          type="text"
          id="state"
          placeholder="State"
          value={state}
          onChange={handleStateChange}
          required
        />

        <input
          className={postcodeValid?"valid-input":"invalid-input"}
          type="text"
          id="postcode"
          placeholder="Postcode"
          value={postcode}
          onChange={handlePostcodeChange}
          required
        />
  
        <button id="make-payment" type="submit" disabled={!isFormValid}>Submit</button>
      </form>
    </div>
    );
};

export default BankDetails;