import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { localStorageGet } from '../../service/common';
import visaCard from '../../assets/Visa.svg'
import binIcon from '../../assets/bin.svg'
import masterCard from '../../assets/MasterCard.svg'
import { applePayCall, applePayMerchantValidation, deleteCard, settlePayment, generateInteracToken } from '../../service/apiService';
import SelectedFunds from '../../components/selectedFunds';
import { googlePayCall } from '../../service/apiService';
import './paymentMethod.css'
//import googlepayRequestData from '../../walletPaymentHelper';
//import config from '../../appConfig'



const PaymentMethod = () => {

/*
 Some elemnts of this code have been commented out
 These are related to GooglePay integration
 We will be implementing it in the future
 Please do not delete the commented code
*/

    const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);


    console.log(window.location.origin);


    let navigate = useNavigate()
    const depositAmount = localStorageGet('selectedFund'); //Get selected Fund from local storage
    const cardNumber = localStorageGet('cardNumber'); //Get saved card, if any
    const userCountry = localStorageGet('userCountry'); //get country code from flutter app
    const userCurrency = localStorageGet('userCurrency'); //Get currency code from flutter app
    const [formattedCardNumber, setFormattedCardNumber] = useState('');
    const [isVisa, setIsVisa] = useState(false);
    const [open, setOpen] = useState(false); //Controller for dialog box 

    const googleScriptUrl = "https://pay.google.com/gp/p/js/pay.js"; //Google Pay Script

    //Generic method to add script to page
    function loadCheckoutScript(src) {
        return new Promise((resolve, reject) => {
            let checkoutScript = document.createElement("script");
            checkoutScript.src = src;
            checkoutScript.onload = resolve;
            checkoutScript.onerror = reject;
            document.head.appendChild(checkoutScript);
        });
    }

    //Add Google Pay to page
    /*
    function addGooglePaymentClientToPage(paymentDataRequest) {
        const paymentsClient =
            new window.google.payments.api.PaymentsClient({ environment: config.ENVIRONMENT });

        const button =
            paymentsClient.createButton({
                buttonColor: 'black',
                buttonType: 'pay',
                buttonSizeMode: 'fill',
                onClick: () => {
                    console.info("CHECK 4 - GOOGLE PAYMENT");
                    paymentsClient.loadPaymentData(paymentDataRequest).then(async function (paymentData) {
                        console.info("CHECK 0 - GOOGLE PAYMENT");
                        const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
                        console.log(paymentToken);
                        const wpGooglePayResponse = await googlePayCall(depositAmount, paymentToken);
                        console.info("CHECK 1 - GOOGLE PAYMENT");
                        console.log(wpGooglePayResponse)
                        if (wpGooglePayResponse) {
                            console.info("CHECK 2 - GOOGLE PAYMENT");
                            settlePayment(wpGooglePayResponse[1], depositAmount, "Deposit through GooglePay");
                            navigate('/paymentResponse?response=success'); //If GPay Authorization passes => Transaction approved
                        } else {
                            console.info("CHECK 3 - GOOGLE PAYMENT");
                            navigate('/paymentResponse?response=failed'); //If GPay Authorization fails => Transaction faild
                        }
                    }).catch(function (err) {
                        // show error in developer console for debugging
                        console.info("CAUGHT")
                        console.info(err);
                    });
                },
                allowedPaymentMethods: [],
            });
        document.getElementById('gpay-button-container').appendChild(button);
    } */



    //Handle Delete card button
    const handleDeleteButtonClick = async () => {
        setOpen(true);
    }

    const handleCardDelete = async () => {
        await deleteCard(cardNumber); //Delete Card

        //Reload Landing Page
        const authToken = localStorageGet('token');
        navigate("/?token=" + authToken + "&amount=" + depositAmount);
    }


    const handleNewCardButton = () => {
        navigate('/checkout?deposit=' + depositAmount)
    }

    const handleInteracButtonClick = async () => {
        const interactWebPage = await generateInteracToken(depositAmount)
        console.log(interactWebPage);
        if (interactWebPage){
            window.location.replace(interactWebPage);
        }
    }

    const handleApplePayPayment = async () => {
        if (isApplePayAvailable) {
            const paymentRequest = {
                total: {
                    label: 'Buddybet',
                    amount: depositAmount,
                },
                countryCode: userCountry,
                currencyCode: userCurrency,
                merchantCapabilities: ['supports3DS'],
                supportedNetworks: ['visa', 'masterCard'],
            };


            const session = new window.ApplePaySession(14, paymentRequest);
            // You would handle the Apple Pay session events and send the payment token to your server for processing.
            // For simplicity, we'll just complete the session.
            session.onvalidatemerchant = async event => {
                const applePaySession = await applePayMerchantValidation()
                session.completeMerchantValidation(applePaySession);
            }

            session.onpaymentauthorized = async event => {
                const applePayWallet = event.payment.token.paymentData; //Wallet Token

                //Make Worldpay API call
                const wpPaymentResponse = await applePayCall(depositAmount, applePayWallet)

                if (wpPaymentResponse[0] == 'authorized') {
                    const result = {
                        "status": window.ApplePaySession.STATUS_SUCCESS
                    };
                    session.completePayment(result);
                    settlePayment(wpPaymentResponse[2], wpPaymentResponse[1], depositAmount, "Deposit through ApplePay",null,null,true);
                    navigate('/paymentResponse?response=success'); //If ApplePay Authorization passes => Transaction approved
                } else {
                    const result = {
                        "status": window.ApplePaySession.STATUS_FAILURE
                    };
                    session.completePayment(result);
                    settlePayment(wpPaymentResponse[1], null, depositAmount, "Deposit through ApplePay failed",null,null,false);
                    navigate('/paymentResponse?response=failed'); //If ApplePay Authorization fails => Transaction rejected
                }
            };

            session.oncancel = event => {
                console.log("cancel validation: ", event)
            };

            session.begin();
        }
    };

    /*const sendRequestToExternalBrowser = () => {
        // Opening a new window with a specific URL parameter
        const externalBrowserWindow = window.open('titan.csit.rmit.edu.au/~s3816605/gpay/index.html?runFunction=true');

        // Optional: You can focus on the external browser window if needed
        // externalBrowserWindow.focus();
    };*/


    useEffect(() => {
        // Check if Apple Pay is available on the user's device and set the state.
        if (window.ApplePaySession) {
            setIsApplePayAvailable(window.ApplePaySession.canMakePayments());
        }
    }, []);


    useEffect(() => {
        if (cardNumber) {
            const formattedNumber = cardNumber.slice(0, 4) + " **** **** " + cardNumber.slice(12, 16)
            setFormattedCardNumber(formattedNumber)
            if (cardNumber.startsWith("4")) {
                setIsVisa(true);
            }
        }
    }, [cardNumber]);

    useEffect(() => {
        loadCheckoutScript(googleScriptUrl)
            .then(() => {
                /*Adding Google Pay
                addGooglePaymentClientToPage(googlepayRequestData(userCountry, userCurrency, depositAmount))*/
            })
            .catch(console.warn);
    }, []);



    return (
        <div className="container">
            <div className="logo">
                <img src={"https://www.buddybet.com/au/wp-content/uploads/sites/2/2022/04/Buddybet-Logo.png"} alt="Logo" />
            </div>
            <div className="title">Choose Payment Method</div>
            
            <SelectedFunds/>

            <div className='payment-options'>
                { userCountry == 'CA' &&
                    (
                    <button id='interac-button' onClick={() => handleInteracButtonClick()}>
                        <div id="interac-button-items">
                            <img src="/Interac.png" alt="Interac" />
                            <div className='text-wrapper'>® Trade-mark of Interac Corp.<br /> Used under license</div>
                        </div>
                    </button>
                    )
                }                

                {formattedCardNumber != '' && (

                    <div className='single-button'>
                        {
                            (() => {
                                if (isVisa) {
                                    return (<span><img src={visaCard}></img></span>)
                                } else {
                                    return (<span><img src={masterCard}></img></span>)
                                }
                            })()
                        }
                        <button id='single-button-cardnumber' onClick={() => { navigate('/savedCard?deposit=' + depositAmount); }}>
                            {formattedCardNumber}
                        </button>


                        <button id='single-button-bin' onClick={handleDeleteButtonClick}><img src={binIcon}></img></button>
                    </div>
                )}
                <button onClick={() => handleNewCardButton()}>
                    Add New Card
                </button>

                <div class="apple-pay-button apple-pay-button-black" onClick={handleApplePayPayment}></div>

            </div>

            {open && (
                <div className="dialog-overlay">
                    <div className="dialog-box">
                        <h2>Confirmation</h2>
                        <p>Are you sure you want to delete the card?</p>
                        <div className="dialog-buttons">
                            <button onClick={() => setOpen(false)}>Cancel</button>
                            <button onClick={handleCardDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )

}

export default PaymentMethod