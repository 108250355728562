import React, {useEffect, useState, useLayoutEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "./newCard.css";
import { tokenRequest } from "../../service/apiService";
import PaymentCalls from "../../service/paymentCalls";
import loaderImg from "../../assets/loader.gif";
import visaCard from "../../assets/Visa.svg";
import masterCard from "../../assets/MasterCard.svg";
import config from '../../appConfig';
import SelectedFunds from "../../components/selectedFunds";


function scriptAlreadyLoaded(src) {
  return document.querySelector(`script[src="${src}"]`);
}

function loadCheckoutScript(src) {
  return new Promise((resolve, reject) => {
    if (scriptAlreadyLoaded(src)) {
      resolve();
      return;
    }

    let checkoutScript = document.createElement("script");
    checkoutScript.src = src;
    checkoutScript.onload = resolve;
    checkoutScript.onerror = reject;
    document.head.appendChild(checkoutScript);
  });
}

function addWorldpayCheckoutToPage() {
  return new Promise((resolve, reject) => {
    (function () {
      window.Worldpay.checkout.init(
        {
          id: config.CHECKOUT_ID,
          form: "#container",
          fields: {
            pan: {
              selector: "#card-pan",
            },
            expiry: {
              selector: "#card-expiry",
            },
            cvv: {
              selector: "#card-cvv",
            },
          },
          styles: {
            "input.is-valid": {
              "color": "green",
            },
            "input.is-invalid": {
              "color": "red",
            },
            "input.is-onfocus": {
              "color": "black",
            },
          },
          enablePanFormatting: true,
        },
        function (error, checkout) {
          if (error) {
            reject(error);
          } else {
            resolve(checkout);
          }
        },
      );
    })();
  });
}

function CheckoutIntegrationSample() {
  const checkoutScriptUrl = config.CHECKOUT_SCRIPT;
  const [checkout, setCheckout] = useState(null); // State to store the checkout object
  const [loading, setLoading] = useState(false); // State to store the loading state

  //Get Query Parameters from Link
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const depositAmount = queryParams.get('deposit'); // Save deposit amount from the main page
  
  const [cardHolderName, setCardHolderName] = useState('');

  let navigate = useNavigate(); // Navigator to push different screens

  const handleCardHolderNameChange = (event) => {
    const regex = /^[a-zA-Z\s]*$/;
    const inputValue = event.target.value;

    if (regex.test(inputValue)) {
      setCardHolderName(inputValue);
    }
  };

  function generateSession() {
    const saveCardCheckbox = document.getElementById("save-card");
    const isSaveCardSelected = saveCardCheckbox.checked; // Has the user selected to save card

    const cardHolderNameInput = document.getElementById("card-holder-name");
    const cardHolderName = cardHolderNameInput.value; // Name entered by the user

    if (checkout && checkout.generateSessionState) {
      checkout.generateSessionState(async function (error, session) {
        if (error) {
          console.warn(`Failed to generate session: ${error}`);
          return;
        }

        // If session received => Proceed with other steps
        if (session) {
          setLoading(true);
          const [tokenizationResponse, paymentAuthorizationLink, maskedCardNumber, maskedCardHolderName] = await tokenRequest(
            session,
            cardHolderName,
            isSaveCardSelected
          ); // Tokenization

          // If card tokenized successfully => Proceed with other steps
          if (tokenizationResponse) {
            PaymentCalls(tokenizationResponse,paymentAuthorizationLink,depositAmount,navigate,maskedCardNumber,maskedCardHolderName);
          } else {
            navigate('/paymentResponse?response=failed')
          }
        } else {
          navigate('/paymentResponse?response=failed')
        }
    });
    }
  }

  useEffect(() => {
    loadCheckoutScript(checkoutScriptUrl)
      .then(() => {
        addWorldpayCheckoutToPage()
          .then((checkoutInstance) => {
            setCheckout(checkoutInstance); // Store the checkout instance in state
          })
          .catch(console.warn);
      })
      .catch(console.warn);
  }, []);

  useLayoutEffect(() => {
    // Make sure to call the remove method (once) in order to deallocate the SDK from memory
    return () => {
      if (checkout && checkout.remove) {
        checkout.remove();
      }
    };
  }, []);

  return (
    <div>
      {loading ? (
        <div className="container" id="loading">
          <img src={loaderImg} alt="Loading" />
          <div id="info" className="info" />
        </div>
        
      ) : (
    <section className="container" id="container">
      <div className="logo-container">
        <img src={"https://www.buddybet.com/au/wp-content/uploads/sites/2/2022/04/Buddybet-Logo.png"} alt="Logo" />
      </div>
      <div className="title-container">Add New Card</div>
      <SelectedFunds/>
      <div id="cardInformation">We accept <img id="cardInfoVisa" src={visaCard}/> and <img id="cardInfoMasterCard" src={masterCard}/></div>
      <section className="card">
        <section id="card-pan" className="field" />
        <section id="card-name">
          <input className="input-field" 
          placeholder="Card Holder Name" 
          id="card-holder-name" value={cardHolderName} onChange={handleCardHolderNameChange}></input>
        </section>
        <section className="columns">
        <section>
        <section id="card-expiry" className="field" />
        </section>
        <section>
        <section id="card-cvv" className="field" />
        </section>
        </section>
        <section className="checkbox-container">
          <label htmlFor="save-card">
            <input type="checkbox" id="save-card" />
            <span className="checkbox-text">Save card for future use</span>
          </label>
        </section>
        </section>
        <div className="button-container">
          <button className="submit" id="new-card-button" type="button" onClick={generateSession}>
            Make Payment
          </button>
        </div>
    </section>
    )}
    </div>

  );
}

export default CheckoutIntegrationSample;