import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import successfulImage from '../../assets/deposit-successfull.svg'
import unsuccessfulImage from '../../assets/deposit-unsuccessfull.svg'
import './paymentDecision.css'
import { gigadatOutcome } from "../../service/apiService";

function PaymentResponse () {

    function DoneButton(){
        if (window.CallWebview && window.CallWebview !== "undefined") {
            window.CallWebview.postMessage("worldpayclosescreen");
          } else {
            console.log(
              `Couldn't find CallWebview. May be not running inside a Flutter webview!`
            );
          }
    }


    //Get Query Parameters from Link
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const response = queryParams.get('response'); //save deposit amount from main page
    const paymentGateway = queryParams.get('gateway'); //To check if redirected by Gigadat
    const identifier = queryParams.get('identifier'); //Will get this query param only in case of gigadat payment

    let isGigadatPayment = false;

    if(paymentGateway){
        isGigadatPayment = true;
        if (identifier){
            //MAKE API CALL TO IRIS IN ORDER TO LOG TRANSACTIONS
            gigadatOutcome(identifier,response);
        }
    }

    let successful = false;
    let unsuccessful = false;

    if (response === "success"){
        successful = true;
    } else {
        unsuccessful = true;
    }

    
    return(
        <div>
            {successful &&(
                <div className='container' id='payment-decision-green'>
                    <div className='payment-decision-image'>
                        <img src={successfulImage}/>
                    </div>
                    {isGigadatPayment && (
                        <h1>
                            Your deposit is in <br /> progress
                        </h1>
                    )}
                    {!isGigadatPayment &&(
                    <h1>
                        Your deposit has been <br /> received
                    </h1>
                    )}
                    <button onClick={DoneButton}>
                    Done
                    </button>
                </div>
            )}

            {unsuccessful && (
                <div id='payment-decision-red' className='container'>
                    <div className='payment-decision-image'>
                        <img src={unsuccessfulImage}/>
                    </div>
                    <h1>
                        Your deposit has been <br /> rejected
                    </h1>
                    <button onClick={DoneButton}>
                    Done
                    </button>

                </div>
            )}
        </div>
        
    )

}
export default PaymentResponse