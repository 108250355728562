import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { userDetails } from '../service/apiService';
import { localStorageGet, localStorageSave } from '../service/common';


/* 
Landing Page which redirects user to deposit or error screen
If the Query Parameter does not include token => Show error
If token is received => Save token => Proceed with deposit
*/
function DepositLanding(props) {
  let navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const selectedFund = queryParams.get('amount');

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        localStorageSave('token', token);
        localStorageSave('selectedFund', selectedFund);

        const isUserDetailsSaved = await saveUserDetails(selectedFund); // Wait for saveUserDetails to finish

        if (isUserDetailsSaved) {
          console.log("check");
          console.log(localStorageGet('selectedFund'));
          console.log(localStorageGet('currentBalance'));
          navigate('/selectPayment');
        } else {
          navigate('/paymentResponse?response=failed');
        }
      } else {
        navigate('/paymentResponse?response=failed');
      }
    };

    fetchData();
  }, []);

  async function saveUserDetails(selectAmount) {
    const userDetailsResponse = await userDetails(selectAmount);

    if (userDetailsResponse) {
      console.log(userDetailsResponse[0]);
      console.log(userDetailsResponse[1]);
      console.log(userDetailsResponse[2]);

      localStorageSave('userCountry', userDetailsResponse[0]);
      localStorageSave('userCurrency', userDetailsResponse[1]);
      localStorageSave('cardNumber', userDetailsResponse[2]);
      return true;
    } else {
      return false;
    }
  }

  return null; // Render appropriate content for DepositLanding component
}

export default DepositLanding;
