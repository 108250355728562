/*
Listener to receive Device Data Collection response
*/
function eventListener() {
    return new Promise((resolve) => {
      const handleMessage = function (event) {
        // This is a Cardinal Commerce URL in live.
      if (
          event.origin === "https://centinelapistag.cardinalcommerce.com" ||
          event.origin === "https://secure-test.worldpay.com/" || 
          event.origin === "https://centinelapi.cardinalcommerce.com"
        ) {
          var data = JSON.parse(event.data);
          console.warn("Merchant received a message:", data);
          if (data !== undefined && data.Status) {
            if (data.Status === true) {
              console.log(data.SessionId);
              resolve(data.SessionId);
            }
          }
        } else {
          resolve(null);
        }
      };
  
      window.addEventListener("message", handleMessage, false);
    });
  }
export default eventListener