import React,{useState} from 'react';
import './CreditCard.css';
import masterCard from '../../assets/MasterCard.svg'
import visaCard from '../../assets/Visa.svg'
import { localStorageGet } from '../../service/common';
import chip from '../../assets/chip.svg'

const CreditCard = () => {

    

const storedCardNumber = localStorageGet("cardNumber");

const formattedCardNumber = storedCardNumber.slice(0,4) + " **** **** " + storedCardNumber.slice(12,16)

const isVisa = storedCardNumber.startsWith("4");



  return (
    <div className="credit-card">
      <div className="card-front">
        <div className="chip"><img src={chip}></img></div>
        <div className="brand">
        {isVisa && (
            <span id='visaImage'><img src={visaCard}></img></span>
        )}
        {!isVisa && (
            <span><img src={masterCard}></img></span>
        )}
        </div>
        <div className="card-number">{formattedCardNumber}</div>
      </div>
    </div>
  );
};

export default CreditCard;
