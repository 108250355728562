import React, {useEffect} from "react";
import './savedCardValidation.css'
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentCalls from '../../service/paymentCalls';
import { cardDetails } from '../../service/apiService';
import { useState } from 'react';
import loaderImg from "../../assets/loader.gif";
import { localStorageGet } from "../../service/common";
import CreditCard from "../../components/CreditCard/CreditCard";
import SelectedFunds from "../../components/selectedFunds";


const ValidationSavedCardScreen = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false); // State to store the loading state

    const userCurrency = localStorageGet('userCurrency'); //Get currency code from flutter app

    //Get Selected Funds from URL Query
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const depositAmount = queryParams.get('deposit'); // Save deposit amount from the main page

    const storedCardNumber = localStorageGet("cardNumber");


    async function makePayment() {
        setLoading(true);
        const cardDetailsResponse = await cardDetails();
        if (cardDetailsResponse){
            //Get Saved Token
            const cardToken = cardDetailsResponse[0];
            const cardAuthorizationLink = cardDetailsResponse[1];

            PaymentCalls(cardToken,cardAuthorizationLink,depositAmount,navigate,storedCardNumber)
        } else {
            navigate('/paymentResponse?response=failed')
        }
        
    }




    return(
        <div>
            {loading ? (
            <div className="container" id="loading">
                <img src={loaderImg} alt="Loading" />
                <div id="info" className="info" />
            </div>):(
            <div className="container" id="container">
                <div className="logo">
                    <img src={"https://www.buddybet.com/au/wp-content/uploads/sites/2/2022/04/Buddybet-Logo.png"} alt="Logo" />
                </div>
                <div className="title">Saved Card</div>
                <SelectedFunds/>
                <CreditCard />
                <div className='button-container'>
                    <button id="saved-card-button" onClick={makePayment}>
                        Make Payment
                    </button>
                </div>
                <div id="info" className="info" />
            </div>
            )}
        </div>
    )
}

export default ValidationSavedCardScreen;