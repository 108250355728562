import eventListener from "../service/eventListener";

export async function ddcFrame (ddcFrameUrl) {
    //Add DDC form Iframe to Payment Page
    if(document.querySelector(".info") != null){
      const infoDiv = document.querySelector(".info");
    infoDiv.innerHTML += `<iframe height="1" width="1" style="display: none;" src=${ddcFrameUrl}></iframe>`
    
    /* 
    The correlationID is returned by the DDC form page as a postMessage()
    if no postMessage() received, retry once after 4 seconds
    */
    try {
      const correlationId = await eventListener();  
      // If 3DS Initialization and DDC is successful
      return correlationId;
    } catch (error) {
      console.error(error.message);
      return null;
    }

    }
    
}

