const commonConfig = {   
  API_DOMAIN: "https://devkong.buddybet.com/clientapidev/v1",
  CHECKOUT_ID: "09521486-18b7-49d3-a26b-e067f3b21573",
  CHECKOUT_SCRIPT: "https://try.access.worldpay.com/access-checkout/v1/checkout.js",
  GOOGLE_PAY_MERCHANT: "2eacc89174832b2",
  ENVIRONMENT: "TEST",
};

const devConfig = {  
  API_DOMAIN: "https://devkong.buddybet.com/clientapidev/v1",  
  CHECKOUT_ID: "09521486-18b7-49d3-a26b-e067f3b21573",
  CHECKOUT_SCRIPT: "https://try.access.worldpay.com/access-checkout/v1/checkout.js",
  GOOGLE_PAY_MERCHANT: "2eacc89174832b2",
  ENVIRONMENT: "TEST",
};

const uatConfig = {  
  API_DOMAIN: "https://devkong.buddybet.com/clientapiuat/v1", 
  CHECKOUT_ID: "09521486-18b7-49d3-a26b-e067f3b21573",
  CHECKOUT_SCRIPT: "https://try.access.worldpay.com/access-checkout/v1/checkout.js",
  GOOGLE_PAY_MERCHANT: "2eacc89174832b2",
  ENVIRONMENT: "TEST",
};

const uatextConfig = {  
  API_DOMAIN: "https://devkong.buddybet.com/clientapiuatext/v1", 
  CHECKOUT_ID: "09521486-18b7-49d3-a26b-e067f3b21573",
  CHECKOUT_SCRIPT: "https://try.access.worldpay.com/access-checkout/v1/checkout.js",
  GOOGLE_PAY_MERCHANT: "2eacc89174832b2",
  ENVIRONMENT: "TEST",
};

const qaConfig = {
  API_DOMAIN: "https://devkong.buddybet.com/clientapiqa/v1", 
  CHECKOUT_ID: "09521486-18b7-49d3-a26b-e067f3b21573",
  CHECKOUT_SCRIPT: "https://try.access.worldpay.com/access-checkout/v1/checkout.js",
  GOOGLE_PAY_MERCHANT: "2eacc89174832b2",
  ENVIRONMENT: "TEST",
};

const preproductionConfig = {
  API_DOMAIN: "https://devkong.buddybet.com/clientapi/v1", 
  CHECKOUT_ID: "09521486-18b7-49d3-a26b-e067f3b21573",
  CHECKOUT_SCRIPT: "https://try.access.worldpay.com/access-checkout/v1/checkout.js",
  GOOGLE_PAY_MERCHANT: "2eacc89174832b2", //change
  ENVIRONMENT: "TEST",
};

const productionConfig = {
  API_DOMAIN: "https://cliapi.buddybet.com/clientapi/v1", 
  CHECKOUT_ID: "74e1ca74-02c8-46e4-9347-387710b6a6fa",
  CHECKOUT_SCRIPT: "https://access.worldpay.com/access-checkout/v1/checkout.js",
  GOOGLE_PAY_MERCHANT: "2eacc89174832b2", //change
  ENVIRONMENT: "PRODUCTION",
};

const env = process.env.REACT_APP_BB_ENV;

export default env === "production" ? productionConfig
  : env === "uat" ? {...commonConfig, ...uatConfig}
   : env === "qa" ? {...commonConfig, ...qaConfig}   
      : env === "dev" ? {...commonConfig, ...devConfig}
        : env === "development" ? {...commonConfig, ...devConfig}
          : env === "uatext" ? {...commonConfig, ...uatextConfig}
            : env === "pre-production" ? {...commonConfig, ...preproductionConfig}
            : commonConfig;
