import React, { useState, useEffect } from 'react';
import { localStorageGet } from '../service/common';

const SelectedFunds = () => {
  const depositAmount = localStorageGet('selectedFund'); //Get selected Fund from local storage
  const userCurrency = localStorageGet('userCurrency'); //Get currency code from flutter app
  const [currencySymbol, setCurrencySymbol] = useState('$');

  useEffect(() => {
    if (userCurrency == 'GBP') {
        setCurrencySymbol('£')
    } else if (userCurrency == 'EUR') {
        setCurrencySymbol('€')
    }
}, [])

  return (
    <div className="balance-section">
      <span className="balance-text">Selected Funds</span>
      <span className="balance-amount">{currencySymbol}{depositAmount}</span>
    </div>
  );
};

export default SelectedFunds;
