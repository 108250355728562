import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Checkout from "./screens/newCard/newCard";
import PaymentResponse from "./screens/paymentDecision/paymentDecision";
import DepositLanding from "./components/landing";
import ValidationSavedCardScreen from "./screens/savedCardValidation/savedCardValidation";
import BankDetails from "./screens/bankDetails/bankDetails";
import PaymentMethod from "./screens/paymentMethod/paymentMethod";

export default function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
              
        <Routes>
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/paymentResponse" element={<PaymentResponse />} />
          <Route path="/savedCard" element={<ValidationSavedCardScreen />} />
          <Route path="/" element={<DepositLanding />} />
          <Route path="bankDetails" element={<BankDetails />} />
          <Route path="/selectPayment" element={<PaymentMethod/>} />
                  
        </Routes>
            
      </BrowserRouter>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
