import { authorizationCall } from "./authorizationCall";
import { authenticate3ds, challengePolling, challengeVerification, initialize3ds } from "./apiService";
import { ddcFrame } from "../components/ddcFrame";
import { challengeForm } from "../components/challengeForm";
import { settlePayment } from "./apiService";

async function PaymentCalls (cardToken, cardAuthorizationLink, depositAmount, navigate, cardNumber=null, cardHolderName=null) {

    const initializationResponse = await initialize3ds(cardToken); // 3DS Initialization

    // Configure Device Data Capture form details
    const ddcJwt = initializationResponse[0];
    const ddcUrl = initializationResponse[1];
    const ddcBin = initializationResponse[2];
    const transactionReference = initializationResponse[3];
    // Build URL for DDC Frame
    const ddcFrameUrl = `https://bbsadevicedatacollwpdev.z8.web.core.windows.net/?ddcUrl=${ddcUrl}&ddcBin=${ddcBin}&ddcJwt=${ddcJwt}`;

    const correlationId = await ddcFrame(ddcFrameUrl); // Add Device Data Capture Iframe and capture correlation ID from response

    // If CorrelationId is generated, proceed with further logic
    if (correlationId) {
      const authenticateResponse = await authenticate3ds(
        depositAmount,
        cardToken,
        correlationId,
        transactionReference
      );

      if (authenticateResponse) {
        // Authentication is challenged => Challenge flow
        if (authenticateResponse[0] === 'challenged') {
          const challengeUrl = authenticateResponse[2];
          const challengeJwt = authenticateResponse[1];
          const challengeRef = authenticateResponse[3];
          const challengeFrameUrl = `https://bbsadevicedatacollwpdev.z8.web.core.windows.net/form_challenge/?url=${challengeUrl}&jwt=${challengeJwt}&md=${transactionReference}`;
          challengeForm(challengeFrameUrl);

          try {
            const challengeCallback = await challengePolling(transactionReference);
            console.log(challengeCallback);
          
              // Verification call
              const verificationResponse = await challengeVerification(transactionReference, challengeRef);
              if (verificationResponse) {
                authorizationCall(
                  verificationResponse,
                  depositAmount,
                  cardToken,
                  cardAuthorizationLink,
                  transactionReference,
                  navigate,
                  cardNumber,
                  cardHolderName
                );
              } else {
                settlePayment(transactionReference, null, depositAmount, "Deposit through Worldpay - Incorrect OTP", cardNumber, cardHolderName, false);
                navigate('/paymentResponse?response=failed'); // If challenge opt fails => Transaction Fails
              }
            
          } catch (error) {
            settlePayment(transactionReference, null, depositAmount, "Deposit through Worldpay -  Verification Failure", cardNumber, cardHolderName, false);
            navigate('/paymentResponse?response=failed'); // Handle the error case => Transaction failed
          }
          
        }
        // Authentication is approved => Call Authorization
        else if (authenticateResponse[0] === 'authenticated') {
          authorizationCall(
            authenticateResponse,
            depositAmount,
            cardToken,
            cardAuthorizationLink,
            transactionReference,
            navigate,
            cardNumber,
            cardHolderName
          );
        }
      } else {
        settlePayment(transactionReference, null, depositAmount, "Deposit through Worldpay - Authentication failed", cardNumber, cardHolderName, false);
        navigate('/paymentResponse?response=failed'); // If Authentication fails => Transaction failed
      }
    } else {
      settlePayment(transactionReference, null, depositAmount, "Deposit through Worldpay - 3DS Failed", cardNumber, cardHolderName, false);
      navigate('/paymentResponse?response=failed'); // If Authentication fails => Transaction failed
    }
}

export default PaymentCalls;